//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as Home } from "../../Media/Icon/002.svg";
//Install
import { ReactComponent as Mail } from "../../Media/Icon/003.svg";
//Install
import { ReactComponent as Chat } from "../../Media/Icon/004.svg";
//Install
import { ReactComponent as Telephone } from "../../Media/Icon/005.svg";

//Element
class Footer extends Component {
    //Element
    Home = () => {
        //Element
        window.location.href = "/"
    }
    //Element
    EMail = () => {
        //Element
        window.location.href = "mailto:info@suciler.com"
    }
    //Element
    Whatsapp = () => {
        //Element
        window.location.href = "https://api.whatsapp.com/send/?phone=+905462207512&text&type=phone_number&app_absent=0"
    }
    //Element
    Call = () => {
        //Element
        window.location.href = "tel:+905462207512"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 vl4W39" },
            //Element
            React.createElement( "div", { className: "float-start w-100 position-fixed start-0 bottom-0 a4U348" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center bg-transparent border-0 tI518x", type: "button", onClick: this.Home },
                                    //Element
                                    React.createElement( Home, {} )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center bg-transparent border-0 tI518x", type: "button", onClick: this.EMail },
                                    //Element
                                    React.createElement( Mail, {} )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center bg-transparent border-0 tI518x", type: "button", onClick: this.Whatsapp },
                                    //Element
                                    React.createElement( Chat, {} )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:3 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center bg-transparent border-0 tI518x", type: "button", onClick: this.Call },
                                    //Element
                                    React.createElement( Telephone, {} )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Footer;