//Install
import React, { Component } from "react";

//Element
class SEO extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 T1Gj62" },
            //Element
            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 Jg006P" },
                //Element
                "Damlama Sulama Sistemleri ile Otomatik Sulama Sistemleri"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 oH646D" },
                //Element
                "Kullanıcı dostu bahçe sulama sistemleri arasında otomatik sulama, damlama sulama, yağmurlama sulama gibi çok sayıda farklı ürün yer alır. Farklı ihtiyaçlara cevap verecek şekilde tasarlanan bu aksesuarlar, bahçenizin yeşil kalmaya devam etmesi için geliştirilmiştir."
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default SEO;