//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Submenu from "../Shortcode/Tab/Submenu";
//Install
import Product from "../Shortcode/Product/Decoder";

//Element
class Decoder extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Decoder : Erzurum Sulama Sistemleri - Suciler.com"
        //Element
        this.Mark()
        //Element
        this.Footer()
    }
    //Element
    Mark = () => {
        //Element
        let Selector = document.querySelector( ".Cs75y0 li:nth-child(3) a" )
        //Element
        Selector.classList.add( "Active" )
    }
    //Element
    Footer = () => {
        //Element
        let Content = document.getElementsByClassName( "Q2tC03" )[0]
        //Element
        Content.classList.remove( "mt-4" )
    }
    //Element
    Click = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Background = document.getElementById( "Product_Lightbox" )
        //Element
        let Title      = document.getElementsByClassName( "e6G973" )[0]
        //Element
        let Summary    = document.getElementsByClassName( "wB264Y" )[0]
        //Element
        let Body       = document.getElementsByTagName( "body" )[0]
        //Element
        Background.classList.remove( "d-none" )
        //Element
        Body.classList.add( "overflow-hidden" )
        //Element
        Title.innerHTML = e.nativeEvent.target.attributes[0].textContent
        //Element
        if( e.nativeEvent.target.attributes[0].textContent === "ACC-99D Decoder" ){
            //Element
            Summary.innerHTML = "Hunter'ın En Güçlü Ticari Kontrol Ünitesinin Çift Kablolu Dekoder Versiyonu"
            //Element
            let Panel = document.getElementById( "Decoder_ACC" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "ACC 2 Decoder" ){
            //Element
            Summary.innerHTML = "The multi-flow monitoring and management capabilities of the ACC2 Decoder Controller make it the best choice for complex projects."
            //Element
            let Panel = document.getElementById( "Decoder_ACC2" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "EZ Decoder System" ){
            //Element
            Summary.innerHTML = "HCC, ICC2 ve Pro-C kontrol üniteleri için devrim niteliğindeki, düşük maliyetli, sorunsuz EZ Dekoder Sistemi ile çift-kablo teknolojisini her zamankinden daha fazla projeye getirin."
            //Element
            let Panel = document.getElementById( "Decoder_EZ" )
            //Element
            Panel.classList.remove( "d-none" )
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Tv84y2" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 pM13z9" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 S7j79P" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:"9 offset-md-3" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative Sm2277" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-5 m-0 mt-3 uP71E5" },
                                        //Element
                                        "Decoder"
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-5 pt-4 pb-4 m-0 mb-5 Ur628J" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "ACC-99D Decoder", title: "ACC-99D Decoder", src: "https://media.suciler.com/product/011.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "ACC-99D Decoder"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "ACC 2 Decoder", title: "ACC 2 Decoder", src: "https://media.suciler.com/product/012.png", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "ACC 2 Decoder"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "EZ Decoder System", title: "EZ Decoder System", src: "https://media.suciler.com/product/013.png", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "EZ Decoder System"
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Submenu, {} ),
            //Element
            React.createElement( Product, {} )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default Decoder;