//Install
import React, { Component } from "react";
//Install
import { BsArrowRight } from "react-icons/bs";

//Element
class Context extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 ps-5 m-0 Ef0d31" },
            //Element
            React.createElement( "h2", { className: "float-start w-auto p-0 m-0 hG439F" },
                //Element
                "Bahçe Sulama Sistemleri İle ",
                //Element
                React.createElement( "br", {} ),
                //Element
                "80% Su Tasarrufu ",
                //Element
                React.createElement( "br", {} ),
                //Element
                "Sağlayın."
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 C3h5b8" },
                //Element
                "Damlama Sulama, Mikro Sulama ve Saha Sulama Sistemleri Konutlar ",
                //Element
                React.createElement( "br", {} ),
                //Element
                "İle Mekanlar İçin 80% Oranlarında Su Tasarrufu ",
                //Element
                React.createElement( "br", {} ),
                //Element
                "Sağlamaktadır."
            ),
            //Element
            React.createElement( "a", { className: "float-start w-auto p-0 m-0 mt-3 A98nj3", href: "/sunulan-hizmetler" },
                //Element
                "Hemen Bakın",
                //Element
                React.createElement( BsArrowRight, {} )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Context;