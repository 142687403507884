//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Cookie from "./Header/Cookie";
//Install
import Brand from "./Header/Brand";
//Install
import SET from "./Header/SET";
//Install
import Menu from "./Header/Menu";
//Install
import Search from "./Header/Search";
//Install
import Submenu from "./Header/Submenu";
//Install
import Mobile from "./Mobile/Default";

//Element
class Header extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 W5d8a8" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 Gq784A" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Cookie, { ID: "0", Title: "Bu sitenin daha iyi, daha hızlı ve güvenli çalışması için çerez kullanılmaktadır. Site üzerinde kullanılan çerezler hakkında detaylı bilgi almak için Çerez Politikaları'nı incelemenizi rica ederiz." } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 Aq134e" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Dc4872" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:2 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative" },
                                    //Element
                                    React.createElement( Brand, {} )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:7 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative" },
                                    //Element
                                    React.createElement( Menu, {} )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative" },
                                    //Element
                                    React.createElement( SET, {} )
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( "div", { className: "d-flex justify-content-center align-items-center position-absolute end-0 top-0 Km40p1" },
                    //Element
                    React.createElement( Search, {} )
                )
            ),
            //Element
            React.createElement( Submenu, {} ),
            //Element
            React.createElement( Mobile, {} )
        )
        //Element
        return (
            <header className="float-start w-100">
                { Content }
            </header>
        );
    }
}
//Element
export default Header;