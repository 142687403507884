//Install
import React, { Component } from "react";

//Element
class SET extends Component {
    //Element
    Click = () => {
        //Element
        window.location.href = "/bilgilendirme"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 aI9762", onClick: this.Click },
            //Element
            React.createElement( "button", { className: "float-start w-auto p-0 m-0 bg-transparent border-0 TqL842", type: "button" },
                //Element
                "Ön Bilgilendirme Talebi"
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default SET;