//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Submenu from "../Shortcode/Tab/Submenu";
//Install
import Product from "../Shortcode/Product/Hydrawise";

//Element
class Hydrawise extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Hydrawise : Erzurum Sulama Sistemleri - Suciler.com"
        //Element
        this.Mark()
        //Element
        this.Footer()
    }
    //Element
    Mark = () => {
        //Element
        let Selector = document.querySelector( ".Cs75y0 li:nth-child(3) a" )
        //Element
        Selector.classList.add( "Active" )
    }
    //Element
    Footer = () => {
        //Element
        let Content = document.getElementsByClassName( "Q2tC03" )[0]
        //Element
        Content.classList.remove( "mt-4" )
    }
    //Element
    Click = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Background = document.getElementById( "Product_Lightbox" )
        //Element
        let Title      = document.getElementsByClassName( "e6G973" )[0]
        //Element
        let Summary    = document.getElementsByClassName( "wB264Y" )[0]
        //Element
        let Body       = document.getElementsByTagName( "body" )[0]
        //Element
        Background.classList.remove( "d-none" )
        //Element
        Body.classList.add( "overflow-hidden" )
        //Element
        Title.innerHTML = e.nativeEvent.target.attributes[0].textContent
        //Element
        if( e.nativeEvent.target.attributes[0].textContent === "HPC" ){
            //Element
            Summary.innerHTML = "Bu akıllı ve esnek kontrol çözümü, popüler Pro-C™ Kontrol Ünitesinin modülerliğini Hydrawise® Yazılımının gücüyle birleştirir."
            //Element
            let Panel = document.getElementById( "Hydrawise_HPC" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "PRO-HC" ){
            //Element
            Summary.innerHTML = "Bireysel ve hafif ticari uygulamalar için bu sağlam ve profesyonel kaliteli Wi-Fi kontrol ünitesini kullanın."
            //Element
            let Panel = document.getElementById( "Hydrawise_PRO_HC" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "HC" ){
            //Element
            Summary.innerHTML = "Konut tipi projeler için uygun maliyetli çözüm olan HC Kontrol Ünitesi, akıllı su tasarrufu ve uzaktan sulama yönetimi özellikleri sunar."
            //Element
            let Panel = document.getElementById( "Hydrawise_HC" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "HC Debi Ölçer" ){
            //Element
            Summary.innerHTML = "Bu sağlam ve kurulumu kolay debi sensörü ile kritik debi bölgesi verilerini ve toplam sistem debilerini tespit edin, izleyin ve raporlayın. Basit ve uygun fiyatlı iletişim kiti ile Hydrawise sistemlerine kablosuz debi izleme özelliğini ekleyin."
            //Element
            let Panel = document.getElementById( "Hydrawise_HC_Debi" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "HCC" ){
            //Element
            Summary.innerHTML = "Hydrawise®'ın gücünü uygun fiyatlı olarak bireysel, ticari ve kamu sektörü projelerine taşıyın."
            //Element
            let Panel = document.getElementById( "Hydrawise_HCC" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "X2" ){
            //Element
            Summary.innerHTML = "Bu çevrimiçi özellikli denetleyici hızlı program programlama ve gelişmiş su tasarrufu özellikleri sunar."
            //Element
            let Panel = document.getElementById( "Hydrawise_X2" )
            //Element
            Panel.classList.remove( "d-none" )
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Tv84y2" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 pM13z9" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 S7j79P" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:"9 offset-md-3" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative Sm2277" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-5 m-0 mt-3 uP71E5" },
                                        //Element
                                        "Hydrawise"
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-5 pt-4 pb-4 m-0 mb-5 Ur628J" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "HPC", title: "HPC", src: "https://media.suciler.com/product/046.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "HPC"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "PRO-HC", title: "PRO-HC", src: "https://media.suciler.com/product/047.png", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "PRO-HC"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "HC", title: "HC", src: "https://media.suciler.com/product/048.png", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "HC"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "HC Debi Ölçer", title: "HC Debi Ölçer", src: "https://media.suciler.com/product/049.png", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "HC Debi Ölçer"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "HCC", title: "HCC", src: "https://media.suciler.com/product/050.png", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "HCC"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "X2", title: "X2", src: "https://media.suciler.com/product/051.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "X2"
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Submenu, {} ),
            //Element
            React.createElement( Product, {} )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default Hydrawise;