//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Submenu from "../Shortcode/Tab/Submenu";
//Install
import Product from "../Shortcode/Product/Macro";

//Element
class Macro extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Ek Parçalar : Erzurum Sulama Sistemleri - Suciler.com"
        //Element
        this.Mark()
        //Element
        this.Footer()
    }
    //Element
    Mark = () => {
        //Element
        let Selector = document.querySelector( ".Cs75y0 li:nth-child(3) a" )
        //Element
        Selector.classList.add( "Active" )
    }
    //Element
    Footer = () => {
        //Element
        let Content = document.getElementsByClassName( "Q2tC03" )[0]
        //Element
        Content.classList.remove( "mt-4" )
    }
    //Element
    Click = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Background = document.getElementById( "Product_Lightbox" )
        //Element
        let Title      = document.getElementsByClassName( "e6G973" )[0]
        //Element
        let Summary    = document.getElementsByClassName( "wB264Y" )[0]
        //Element
        let Body       = document.getElementsByTagName( "body" )[0]
        //Element
        Background.classList.remove( "d-none" )
        //Element
        Body.classList.add( "overflow-hidden" )
        //Element
        Title.innerHTML = e.nativeEvent.target.attributes[0].textContent
        //Element
        if( e.nativeEvent.target.attributes[0].textContent === "Kök Bölgesi Sulama" ){
            //Element
            Summary.innerHTML = "Daha sağlıklı ağaçlar ve çalılar için meselenin köküne inilmeli"
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Damlama Kontrol Kitleri" ){
            //Element
            Summary.innerHTML = "Ticari Saha Koşulları İçin Tasarlanmış Dayanıklı ve Kullanışlı Bölge Kontrol Kitleri"
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Damla Sulama Emitörleri" ){
            //Element
            Summary.innerHTML = "Doğru miktarda su, doğrudan bitkiye"
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Besleme Boruları" ){
            //Element
            Summary.innerHTML = "17 mm profesyonel polietilen boru"
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Tahliye Vanaları" ){
            //Element
            Summary.innerHTML = "Başlatma sırasında havayı boşaltarak ve havanın kapanırken içeri girmesini sağlayarak koç darbesini ve sistemin çökmesini önleyin."
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Çok Girişli Emitörler" ){
            //Element
            Summary.innerHTML = "Basıncı Regüler Eden Debi 2,0, 4,0, 8,0, 15,0 l/sa"
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Sert Yükseltici" ){
            //Element
            Summary.innerHTML = "Sağlam sistem tasarımları için dizayn edildi. 10-32 dişli bileşenler kullanır. Bir yıllık çiçek yatakları ve saksılar için harika bir çözüm sunar."
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Otomatik Yıkama Vanaları" ){
            //Element
            Summary.innerHTML = "Her sistem başlangıcında su, hava ve kiri otomatik olarak yıkayarak lateralleri temiz tutun."
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Multi Purpose Box" ){
            //Element
            Summary.innerHTML = "This sturdy box is just right size to provide protection and easy access to essential irrigation components."
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Tv84y2" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 pM13z9" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 S7j79P" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:"9 offset-md-3" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative Sm2277" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-5 m-0 mt-3 uP71E5" },
                                        //Element
                                        "Ek Parçalar"
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-5 pt-4 pb-4 m-0 mb-5 Ur628J" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Kök Bölgesi Sulama", title: "Kök Bölgesi Sulama", src: "https://media.suciler.com/product/014.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "Kök Bölgesi Sulama"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Damlama Kontrol Kitleri", title: "Damlama Kontrol Kitleri", src: "https://media.suciler.com/product/015.png", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "Damlama Kontrol Kitleri"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Damla Sulama Emitörleri", title: "Damla Sulama Emitörleri", src: "https://media.suciler.com/product/017.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "Damla Sulama Emitörleri"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Besleme Boruları", title: "Besleme Boruları", src: "https://media.suciler.com/product/018.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "Besleme Boruları"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Tahliye Vanaları", title: "Tahliye Vanaları", src: "https://media.suciler.com/product/019.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "Tahliye Vanaları"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Çok Girişli Emitörler", title: "Çok Girişli Emitörler", src: "https://media.suciler.com/product/020.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "Çok Girişli Emitörler"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Sert Yükseltici", title: "Sert Yükseltici", src: "https://media.suciler.com/product/021.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "Sert Yükseltici"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Otomatik Yıkama Vanaları", title: "Otomatik Yıkama Vanaları", src: "https://media.suciler.com/product/022.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "Otomatik Yıkama Vanaları"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Multi Purpose Box", title: "Multi Purpose Box", src: "https://media.suciler.com/product/023.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "Multi Purpose Box"
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Submenu, {} ),
            //Element
            React.createElement( Product, {} )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default Macro;