//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Submenu from "../Shortcode/Tab/Submenu";
//Install
import Product from "../Shortcode/Product/Micro";

//Element
class Micro extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Mikro Sulama : Erzurum Sulama Sistemleri - Suciler.com"
        //Element
        this.Mark()
        //Element
        this.Footer()
    }
    //Element
    Mark = () => {
        //Element
        let Selector = document.querySelector( ".Cs75y0 li:nth-child(3) a" )
        //Element
        Selector.classList.add( "Active" )
    }
    //Element
    Footer = () => {
        //Element
        let Content = document.getElementsByClassName( "Q2tC03" )[0]
        //Element
        Content.classList.remove( "mt-4" )
    }
    //Element
    Click = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Background = document.getElementById( "Product_Lightbox" )
        //Element
        let Title      = document.getElementsByClassName( "e6G973" )[0]
        //Element
        let Summary    = document.getElementsByClassName( "wB264Y" )[0]
        //Element
        let Body       = document.getElementsByTagName( "body" )[0]
        //Element
        Background.classList.remove( "d-none" )
        //Element
        Body.classList.add( "overflow-hidden" )
        //Element
        Title.innerHTML = e.nativeEvent.target.attributes[0].textContent
        //Element
        if( e.nativeEvent.target.attributes[0].textContent === "ECO MAT" ){
            //Element
            Summary.innerHTML = "Yüzey Atu Sulamada Çarpıcı Bir Yenilik"
            //Element
            let Panel = document.getElementById( "Micro_Ecomat" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "PLD 16 MM" ){
            //Element
            Summary.innerHTML = "Güvenilirlik ve Verim İçin Tasarlanan Hunter PLD Profesyonel Sınıftır"
            //Element
            let Panel = document.getElementById( "Micro_PLD16" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "HDL" ){
            //Element
            Summary.innerHTML = "Hunter Damla Sulama HDL-CV, HDL-PC, HDL-R"
            //Element
            let Panel = document.getElementById( "Micro_HDL" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "MLD" ){
            //Element
            Summary.innerHTML = "Mini Peyzaj Damlama Borusu"
            //Element
            let Panel = document.getElementById( "Micro_MLD" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "PLD ML" ){
            //Element
            Summary.innerHTML = "PLD ML"
            //Element
            let Panel = document.getElementById( "Micro_Pldml" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Eco Wrap" ){
            //Element
            Summary.innerHTML = "ECO-WRAP"
            //Element
            let Panel = document.getElementById( "Micro_Ecowrap" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "BTT" ){
            //Element
            Summary.innerHTML = "Akıllı telefonla kontrol edilen, musluk ucuna daha kolay erişim için yerin üzerinde yapılan sulamadan yararlanın."
            //Element
            let Panel = document.getElementById( "Micro_BTT" )
            //Element
            Panel.classList.remove( "d-none" )
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Tv84y2" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 pM13z9" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 S7j79P" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:"9 offset-md-3" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative Sm2277" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-5 m-0 mt-3 uP71E5" },
                                        //Element
                                        "Mikro Sulama"
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-5 pt-4 pb-4 m-0 mb-5 Ur628J" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "ECO MAT", title: "ECO MAT", src: "https://media.suciler.com/product/024.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "ECO MAT"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "PLD 16 MM", title: "PLD 16 MM", src: "https://media.suciler.com/product/025.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "PLD 16 MM"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "HDL", title: "HDL", src: "https://media.suciler.com/product/026.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "HDL"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "MLD", title: "MLD", src: "https://media.suciler.com/product/027.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "MLD"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "PLD ML", title: "PLD ML", src: "https://media.suciler.com/product/028.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "PLD ML"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Eco Wrap", title: "Eco Wrap", src: "https://media.suciler.com/product/029.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "Eco Wrap"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "BTT", title: "BTT", src: "https://media.suciler.com/product/030.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "BTT"
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Submenu, {} ),
            //Element
            React.createElement( Product, {} )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default Micro;