//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Contact extends Component {
    //Element
    render() {
        //Element
        const { Number, Mail } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 j4GI73" },
            //Element
            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 lh-1 u77f0W" },
                //Element
                "Kurumsal Bilgilendirme"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 Ay4275" },
                //Element
                "Çağrı merkezi numaraları 08.00 ile 18.00 saatleri arasında tüm talepleriniz için hizmetinizdedir."
            ),
            //Element
            React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-3 n1G33K" },
                //Element
                Number
            ),
            //Element
            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-5 lh-1 u77f0W" },
                //Element
                "Hemen Bilgi Edinin"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 Ay4275" },
                //Element
                "Senin icin daha neler yapabiliriz? Bizimle hemen iletişim kurun, sizden haber bekliyoruz."
            ),
            //Element
            React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-3 n1G33K" },
                //Element
                Mail
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
Contact.propTypes = {
    ID     : propTypes.string.isRequired,
    Number : propTypes.string.isRequired,
    Mail  : propTypes.string.isRequired
}
//Element
export default Contact;