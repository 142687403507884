//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Sitemap from "../Shortcode/Tab/Sitemap";
//Install
import Submenu from "../Shortcode/Tab/Submenu";

//Element
class Corporation extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Kurumsal : Erzurum Sulama Sistemleri - Suciler.com"
        //Element
        this.Mark()
        //Element
        this.Footer()
    }
    //Element
    Mark = () => {
        //Element
        let Selector = document.querySelector( ".Cs75y0 li:first-child a" )
        //Element
        Selector.classList.add( "Active" )
    }
    //Element
    Footer = () => {
        //Element
        let Content = document.getElementsByClassName( "Q2tC03" )[0]
        //Element
        Content.classList.remove( "mt-4" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Tv84y2" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 pM13z9" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 S7j79P" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:"9 offset-md-3" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative Sm2277" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-5 m-0 mt-3 uP71E5" },
                                        //Element
                                        "Misyonumuz, hizmet verdiğimiz bölgede en yüksek kalitede ürünler ve hizmetler sunmaktır."
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-5 pt-4 pb-4 m-0 mb-5 Ur628J" },
                                        //Element
                                        React.createElement( "p", {},
                                            //Element
                                            "Misyonumuz, hizmet verdiğimiz bölgelerde en yüksek kalitede ürünler ve hizmetler sunabilmektir. Bu hedefe müşteri memnuniyeti, inovasyon, aile ve vatandaşlık temel değerlerimizden taviz vermeden ulaşacağız."
                                        ),
                                        //Element
                                        React.createElement( "p", {},
                                            //Element
                                            "Müşterilerimiz için yüksek düzeyde müşteri memnuniyeti sunmaya kendimizi adadık. Verdiğimiz kararlarda sorumlu davranacağız, her türlü ilişkide saygılı olacağız ve taahhütlerimizi yerine getireceğiz."
                                        ),
                                        //Element
                                        React.createElement( "p", {},
                                            //Element
                                            "Hunter tarafından şekillendirilen kalite ve inovasyon geleneğimiz yaptığımız her işin özüdür. Son teknoloji ürünler ve prosesler geliştiriyoruz ve sürekli geliştirme ve iyileştirmeye odaklanıyoruz."
                                        ),
                                        //Element
                                        React.createElement( "p", {},
                                            //Element
                                            "İçinde yaşadığımız topluluğa hizmet etmeye kendimizi adadık. Çevrelerini geliştirmeye kararlı olan çalışanlarımızın çabalarını destekleme sorumluluğumuza önem veriyoruz."
                                        ),
                                        //Element
                                        React.createElement( "p", {},
                                            //Element
                                            "Bölgesel bir işletme olarak çevre üzerindeki etkimizi azaltarak ve doğal kaynakların daha verimli şekilde kullanımını destekleyen daha gelişmiş yöntemler ve ürünler geliştirerek gezegeni korumaya yönelik adımlar atacağız."
                                        )
                                    )
                                )
                            )
                        )
                    )
                ),
            ),
            //Element
            React.createElement( Submenu, {} )
        )
        //Element
        return (
            <main className="float-start w-100 position-relative">
                { Content }
            </main>
        );
    }
}
//Element
export default Corporation;