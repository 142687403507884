//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Help extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Photo, Target } = this.props
        //Element
        let Image = "https://media.suciler.com/featured/"
        //Element
        let URL   = Image + Photo
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 position-relative Po583d", href: Target },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-4 m-0 dT3840" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: URL, width: "416", height: "277" } )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-4 pt-1 m-0 Lx375Z" },
                //Element
                React.createElement( "h4", { className: "float-start w-100 p-0 pb-3 m-0 mb-2 e8A61l" },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 j84N4b" },
                    //Element
                    Summary
                ),
                //Element
                React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 mb-3 F70ce3" },
                    //Element
                    "Daha fazla bilgi edinin"
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
Help.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Photo   : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Help;