//Install
import React, { Component } from "react";
//Install
import { BsArrowUpRight } from "react-icons/bs";

//Element
class Sitemap extends Component {
    //Element
    Click = () => {
        //Element
        window.location.href = "https://suciler.com/sitemap.xml"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 Z12c9K" },
            //Element
            React.createElement( "button", { className: "float-start w-auto p-0 m-0 bg-transparent border-0 Kqh992", type: "button", onClick: this.Click },
                //Element
                "Site Haritaları",
                //Element
                React.createElement( BsArrowUpRight, {} )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Sitemap;