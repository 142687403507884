//Install
import React, { Component } from "react";
//Install
import { IoChevronForwardOutline } from "react-icons/io5";

//Element
class Submenu extends Component {
    //Element
    Click = () => {
        //Element
        window.location.href = "/bilgilendirme"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 r9714B" },
            //Element
            React.createElement( "button", { className: "d-flex w-100 justify-content-between align-items-center p-0 ps-4 pe-4 m-0 position-absolute start-0 top-0 Pr7119", type: "button", onClick: this.Click },
                //Element
                React.createElement( "span", { className: "float-start w-auto p-0 m-0 mt-1 gR335O" },
                    //Element
                    "Ön Bilgilendirme Talebi"
                ),
                //Element
                React.createElement( "label", { className: "float-start w-auto p-0 m-0 Gq1743" },
                    //Element
                    React.createElement( IoChevronForwardOutline, {} )
                )
            ),
            //Element
            React.createElement( "ul", { className: "float-start w-100 p-0 m-0 Cs75y0" },
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 ps-4 pe-4 Gq1743", href: "/kurumsal" },
                        //Element
                        React.createElement( "span", { className: "d-flex w-100 justify-content-between align-items-center" },
                            //Element
                            "Kurumsal",
                            //Element
                            React.createElement( IoChevronForwardOutline, {} )
                        )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 ps-4 pe-4 Gq1743", href: "/sunulan-hizmetler" },
                        //Element
                        React.createElement( "span", { className: "d-flex w-100 justify-content-between align-items-center" },
                            //Element
                            "Sunulan Hizmetler",
                            //Element
                            React.createElement( IoChevronForwardOutline, {} )
                        )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 ps-4 pe-4 Gq1743", href: "/parcalar" },
                        //Element
                        React.createElement( "span", { className: "d-flex w-100 justify-content-between align-items-center" },
                            //Element
                            "Parçalar",
                            //Element
                            React.createElement( IoChevronForwardOutline, {} )
                        )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 ps-4 pe-4 Gq1743", href: "/medya" },
                        //Element
                        React.createElement( "span", { className: "d-flex w-100 justify-content-between align-items-center" },
                            //Element
                            "Medya",
                            //Element
                            React.createElement( IoChevronForwardOutline, {} )
                        )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 ps-4 pe-4 Gq1743", href: "/referanslar" },
                        //Element
                        React.createElement( "span", { className: "d-flex w-100 justify-content-between align-items-center" },
                            //Element
                            "Referanslar",
                            //Element
                            React.createElement( IoChevronForwardOutline, {} )
                        )
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-100" },
                    //Element
                    React.createElement( "a", { className: "float-start w-100 p-0 ps-4 pe-4 Gq1743", href: "/hemen-ulasin" },
                        //Element
                        React.createElement( "span", { className: "d-flex w-100 justify-content-between align-items-center" },
                            //Element
                            "Hemen Ulaşın",
                            //Element
                            React.createElement( IoChevronForwardOutline, {} )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Submenu;