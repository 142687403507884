//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import emailjs from "@emailjs/browser";

//Element
class Form extends Component {
    //Element
    Send = async(e) => {
        //Element
        e.preventDefault()
        //Element
        emailjs.sendForm( process.env.REACT_APP_Web_GG990T, process.env.REACT_APP_Web_BJ476J, "#React_Nl4A07", process.env.REACT_APP_Web_K8NY19 )
        //Element
        .then( (result) => {
            //Element
            if( result.text === "OK" ){
                //Element
                let Tlu297 = document.getElementsByClassName( "Tlu297" )[0]
                //Element
                Tlu297.classList.toggle( "d-none" )
            }
        }, (error) => {
            //Element
            console.log( error.text )
        })
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "form", { id: "React_Nl4A07", name: "React_Nl4A07", className: "float-start w-100 p-0 ps-5 pt-5 m-0 Zf493o", onSubmit: this.Send },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 Xj06u6" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-3 J022yx" },
                            //Element
                            "Müşteri Hizmetleri olarak size destek olmak için buradayız."
                        ),
                        //Element
                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 yZ9a75" },
                            //Element
                            "Müşteri hizmetlerinden her konuda bilgi alabilirsiniz."
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 D0f783" },
                        //Element
                        React.createElement( "input", { id: "React_D948Y1", name: "React_D948Y1", className: "float-start w-100 p-0 pe-4 ps-4 m-0 p8M5k8", placeholder: "Ad", type: "text", required: "required" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 D0f783" },
                        //Element
                        React.createElement( "input", { id: "React_NE277E", name: "React_NE277E", className: "float-start w-100 p-0 pe-4 ps-4 m-0 p8M5k8", placeholder: "Soyad", type: "text", required: "required" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 D0f783" },
                        //Element
                        React.createElement( "input", { id: "React_T8L246", name: "React_T8L246", className: "float-start w-100 p-0 pe-4 ps-4 m-0 p8M5k8", placeholder: "Telefon numaranız", type: "text", required: "required" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:6 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 D0f783" },
                        //Element
                        React.createElement( "input", { id: "React_N66431", name: "React_N66431", className: "float-start w-100 p-0 pe-4 ps-4 m-0 p8M5k8", placeholder: "E-posta", type: "text", required: "required" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 D0f783" },
                        //Element
                        React.createElement( "textarea", { id: "React_B890G7", name: "React_B890G7", className: "float-start w-100 p-4 pt-3 pb-3 m-0 Rf4458", placeholder: "Bilgilendirme", rows: "4", cols: "50", required: "required" } )
                    )
                ),
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 D0f783" },
                        //Element
                        React.createElement( "button", { id: "React_U70227", name: "React_U70227", className: "float-start p-0 m-0 text-center Tg352A", type: "submit" },
                            //Element
                            "Hemen Gönder"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:12 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 D0f783" },
                        //Element
                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 position-relative d-none Tlu297" },
                            //Element
                            "Tebrikler, Başarı İle Gönderildi."
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Form;