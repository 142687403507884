//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { IoCloseOutline } from "react-icons/io5";

//Element
class Lightbox extends Component {
    //Element
    Click = () => {
        //Element
        let Background = document.getElementsByClassName( "J5bv36" )[0]
        //Element
        let Box        = document.getElementsByClassName( "pB7g34" )[0]
        //Element
        let Body       = document.getElementsByTagName( "body" )[0]
        //Element
        Background.classList.add( "d-none" )
        //Element
        Box.classList.add( "d-none" )
        //Element
        Body.classList.remove( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center position-fixed start-0 top-0 d-none J5bv36" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 A0v6a5" },
                            //Element
                            React.createElement( "div", { className: "float-start w-auto p-4 m-0 position-relative d-none pB7g34" },
                                //Element
                                React.createElement( "img", { id: "Lightbox", alt: "Sulama Sistemleri", title: "Sulama Sistemleri", src: "https://media.suciler.com/content/019.jpg", width: "100", height: "100" } ),
                                //Element
                                React.createElement( "button", { className: "d-flex justify-content-center align-items-center position-absolute end-0 top-0 xT8051", type: "button", onClick: this.Click },
                                    //Element
                                    React.createElement( IoCloseOutline, {} )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Lightbox;