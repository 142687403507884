//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import emailjs from "@emailjs/browser";
//Install
import Submenu from "../Shortcode/Tab/Submenu";

//Element
class Information extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Bilgilendirme Talebi : Erzurum Sulama Sistemleri - Suciler.com"
        //Element
        this.Footer()
    }
    //Element
    Footer = () => {
        //Element
        let Content = document.getElementsByClassName( "Q2tC03" )[0]
        //Element
        Content.classList.remove( "mt-4" )
    }
    //Element
    Send = async(e) => {
        //Element
        e.preventDefault()
        //Element
        emailjs.sendForm( process.env.REACT_APP_Web_GG990T, process.env.REACT_APP_Web_BJ476J, "#React_Nl4A07", process.env.REACT_APP_Web_K8NY19 )
        //Element
        .then( (result) => {
            //Element
            if( result.text === "OK" ){
                //Element
                let mG3J91 = document.getElementsByClassName( "mG3J91" )[0]
                //Element
                mG3J91.classList.toggle( "d-none" )
            }
        }, (error) => {
            //Element
            console.log( error.text )
        })
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Tv84y2" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 pM13z9" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 S7j79P" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:"9 offset-md-3" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative Sm2277" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-5 m-0 mt-3 bg-white uP71E5" },
                                        //Element
                                        "Talebiniz ile seçmiş olduğunuz konu başlığı'nın uyuşmasına dikkat ediniz, yanlış seçim durumunda talebiniz değerlendirilememektedir."
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-5 pt-4 pb-4 m-0 mb-5 zJ415U" },
                                        //Element
                                        React.createElement( "form", { id: "React_Nl4A07", name: "React_Nl4A07", className: "float-start w-100 p-0 m-0 jJ307S", onSubmit: this.Send },
                                            //Element
                                            React.createElement( Row, {},
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 N1g26Z" },
                                                        //Element
                                                        React.createElement( "input", { id: "React_D948Y1", name: "React_D948Y1", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-2 Px42o4", placeholder: "Ad", type: "text", required: "required" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 N1g26Z" },
                                                        //Element
                                                        React.createElement( "input", { id: "React_NE277E", name: "React_NE277E", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-2 Px42o4", placeholder: "Soyad", type: "text", required: "required" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 N1g26Z" },
                                                        //Element
                                                        React.createElement( "input", { id: "React_T8L246", name: "React_T8L246", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-2 Px42o4", placeholder: "Telefon numaranız", type: "text", required: "required" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 N1g26Z" },
                                                        //Element
                                                        React.createElement( "input", { id: "React_N66431", name: "React_N66431", className: "float-start w-100 p-0 pe-4 ps-4 m-0 mt-3 Px42o4", placeholder: "E-posta", type: "text", required: "required" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:4 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 N1g26Z" },
                                                        //Element
                                                        React.createElement( "select", { id: "React_W3814A", name: "React_W3814A", className: "float-start w-100 p-0 pe-4 ps-4 mt-3 P3Q1n6", required: "required" },
                                                            //Element
                                                            React.createElement( "option", { value: "" },
                                                                //Element
                                                                "Konu Başlığı"
                                                            ),
                                                            //Element
                                                            React.createElement( "option", { value: "Bilgilendirme Talebi" },
                                                                //Element
                                                                "Bilgilendirme Talebi"
                                                            ),
                                                            //Element
                                                            React.createElement( "option", { value: "Kurulum Talebi" },
                                                                //Element
                                                                "Kurulum Talebi"
                                                            ),
                                                            //Element
                                                            React.createElement( "option", { value: "Bakım Talebi" },
                                                                //Element
                                                                "Bakım Talebi"
                                                            ),
                                                            //Element
                                                            React.createElement( "option", { value: "Tamir Talebi" },
                                                                //Element
                                                                "Tamir Talebi"
                                                            )
                                                        )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:12 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 N1g26Z" },
                                                        //Element
                                                        React.createElement( "textarea", { id: "React_B890G7", name: "React_B890G7", className: "float-start w-100 p-4 pt-3 pb-3 m-0 mt-3 hR684S", placeholder: "Ön Bilgilendirme", rows: "4", cols: "50", required: "required" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:12 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 N1g26Z" },
                                                        //Element
                                                        React.createElement( "button", { id: "React_A8Z58O", name: "React_A8Z58O", className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 mt-3 uI93C5", type: "submit" },
                                                            //Element
                                                            "Hemen Gönder"
                                                        )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:12 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 N1g26Z" },
                                                        //Element
                                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-3 d-none mG3J91" },
                                                            //Element
                                                            "Tebrikler, Başarı İle Gönderildi."
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Submenu, {} )
        )
        //Element
        return (
            <main className="float-start w-100 position-relative">
                { Content }
            </main>
        );
    }
}
//Element
export default Information;