//Install
import React, { Component } from "react";
//Install
import A6w876 from "../../Media/Video/001.mp4";

//Element
class Shape extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-5 m-0 T4nG47" },
            //Element
            React.createElement( "figure", { className: "float-start w-100 p-0 m-0 fa66F7" },
                //Element
                React.createElement( "video", { width: "540", height: "540", loop: true, muted: true, autoPlay: true, playsInline: true },
                    //Element
                    React.createElement( "source", { src: A6w876, type: "video/mp4" } )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Shape;