//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as Icon } from "../../Media/Icon/001.svg";
//Install
import { ReactComponent as Hamburger } from "../../Media/Icon/006.svg";
//Install
import { ReactComponent as Close } from "../../Media/Icon/007.svg";
//Install
import { ReactComponent as Phone } from "../../Media/Icon/005.svg";

//Element
class Default extends Component {
    //Element
    Home = () => {
        //Element
        window.location.href = "/"
    }
    //Element
    Toggle = () => {
        //Element 
        let Menu      = document.getElementsByClassName( "Tl356z" )[0]
        //Element
        let Hamburger = document.getElementsByClassName( "T7Vz96" )[0]
        //Element
        let Close     = document.getElementsByClassName( "TH4u73" )[0]
        //Element
        Hamburger.classList.toggle( "d-none" )
        //Element
        Close.classList.toggle( "d-none" )
        //Element
        Menu.classList.toggle( "d-block" )
    }
    //Element
    Call = () => {
        //Element
        window.location.href = "tel:+905462207512"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Uc48Q7" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative" },
                            //Element
                            React.createElement( "button", { className: "d-flex w-100 justify-content-start align-items-center bg-transparent border-0 T7Vz96", type: "button", onClick: this.Toggle },
                                //Element
                                React.createElement( Hamburger, {} )
                            ),
                            //Element
                            React.createElement( "button", { className: "d-flex w-100 justify-content-start align-items-center bg-transparent border-0 d-none TH4u73", type: "button", onClick: this.Toggle },
                                //Element
                                React.createElement( Close, {} )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:8 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative" },
                            //Element
                            React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center bg-transparent border-0 w0B656", type: "button", onClick: this.Home },
                                //Element
                                React.createElement( Icon, {} ),
                                //Element
                                React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-2" }, "Suciler" )
                            )
                        )
                    ),
                    //Element
                    React.createElement( Col, { md:2 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative" },
                            //Element
                            React.createElement( "button", { className: "d-flex w-100 justify-content-end align-items-center bg-transparent border-0 W7gM58", type: "button", onClick: this.Call },
                                //Element
                                React.createElement( Phone, {} )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Default;