//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Sitemap from "../Shortcode/Tab/Sitemap";
//Install
import Submenu from "../Shortcode/Tab/Submenu";

//Element
class Career extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Kariyer Fırsatları : Erzurum Sulama Sistemleri - Suciler.com"
        //Element
        this.Footer()
    }
    //Element
    Footer = () => {
        //Element
        let Content = document.getElementsByClassName( "Q2tC03" )[0]
        //Element
        Content.classList.remove( "mt-4" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Tv84y2" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 pM13z9" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 S7j79P" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:"9 offset-md-3" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative Sm2277" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-5 m-0 mt-3 uP71E5" },
                                        //Element
                                        "Geleneğimiz, uzmanlığımız, profesyonelliğimiz ve azmimiz, hızlı ve sürdürülebilir şekilde büyümemizi sağlıyor."
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-5 pt-4 pb-4 m-0 mb-5 Ur628J" },
                                        //Element
                                        React.createElement( "p", {},
                                            //Element
                                            "Değerlerimiz işimizin temelidir. İşletmemizin temel taşı olan bu değerler vizyonumuzu destekler ve kültürümüze şekil verir ve hangi yönde ilerleyeceğimizi belirler. Bu değerler bize yol gösterir. Değerlerimiz izleyeceğimiz yolu açıkça gösterir, birlikte büyümemize yardımcı olur ve hepimize başarı ruhunu aşılar."
                                        ),
                                        //Element
                                        React.createElement( "p", {},
                                            //Element
                                            "Girişimci ruhumuzu paylaşmamız sayesinde müşterilerimizin işletmelerin çıkarları doğrultusunda proaktif, cesur ve sorumlu bir şekilde hareket ediyoruz."
                                        ),
                                        //Element
                                        React.createElement( "p", {},
                                            //Element
                                            "İşimizi tutkuyla yapıyoruz; mükemmel performansı yakalamak için kendimize meydan okuyor, tüm engelleri aşmak için azimle çabalıyoruz. Tutkulu bir şekilde birlikte çalışarak müşterilerimize eşsiz deneyim sunuyoruz."
                                        ),
                                        //Element
                                        React.createElement( "p", {},
                                            //Element
                                            "Her insanın kendine özgü bir değer kattığına inanıyoruz. Etik yaklaşım, karşılıklı saygı ve takım ruhunu temel alarak içten ilişkiler kuruyoruz. Müşterilerimizin memnuniyetine ve sadakatine gerçekten önem veriyoruz."
                                        ),
                                        //Element
                                        React.createElement( "button", { className: "float-start p-0 m-0 mt-1 text-center Pi544T", type: "button" },
                                            //Element
                                            "Hemen Başvur"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Submenu, {} )
        )
        //Element
        return (
            <main className="float-start w-100 position-relative">
                { Content }
            </main>
        );
    }
}
//Element
export default Career;