//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Sitemap from "../Shortcode/Tab/Sitemap";
//Install
import Submenu from "../Shortcode/Tab/Submenu";

//Element
class Media extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Medya Galeri : Erzurum Sulama Sistemleri - Suciler.com"
        //Element
        this.Mark()
        //Element
        this.Footer()
    }
    //Element
    Mark = () => {
        //Element
        let Selector = document.querySelector( ".Cs75y0 li:nth-child(4) a" )
        //Element
        Selector.classList.add( "Active" )
    }
    //Element
    Footer = () => {
        //Element
        let Content = document.getElementsByClassName( "Q2tC03" )[0]
        //Element
        Content.classList.remove( "mt-4" )
    }
    //Element
    Click = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Background = document.getElementsByClassName( "J5bv36" )[0]
        //Element
        let Box        = document.getElementsByClassName( "pB7g34" )[0]
        //Element
        let Body       = document.getElementsByTagName( "body" )[0]
        //Element
        let URL = e.target.currentSrc
        //Element
        let Image      = document.getElementById( "Lightbox" )
        //Element
        Image.src      = URL
        //Element
        Background.classList.remove( "d-none" )
        //Element
        Box.classList.remove( "d-none" )
        //Element
        Body.classList.add( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Tv84y2" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 pM13z9" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 S7j79P" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:"9 offset-md-3" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative Sm2277" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-5 m-0 mt-3 uP71E5" },
                                        //Element
                                        "Medya Galeri"
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-5 pt-4 pb-4 m-0 mb-5 Ur628J" },
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 Wy378q" },
                                            //Element
                                            React.createElement( Row, {},
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 k6YS15", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Sulama Sistemleri", title: "Sulama Sistemleri", src: "https://media.suciler.com/content/008.jpg", width: "158", height: "158" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 k6YS15", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Sulama Sistemleri", title: "Sulama Sistemleri", src: "https://media.suciler.com/content/010.jpg", width: "158", height: "158" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 k6YS15", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Sulama Sistemleri", title: "Sulama Sistemleri", src: "https://media.suciler.com/content/011.jpg", width: "158", height: "158" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 k6YS15", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Sulama Sistemleri", title: "Sulama Sistemleri", src: "https://media.suciler.com/content/012.jpg", width: "158", height: "158" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 k6YS15", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Sulama Sistemleri", title: "Sulama Sistemleri", src: "https://media.suciler.com/content/013.jpg", width: "158", height: "158" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 k6YS15", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Sulama Sistemleri", title: "Sulama Sistemleri", src: "https://media.suciler.com/content/014.jpg", width: "158", height: "158" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 k6YS15", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Sulama Sistemleri", title: "Sulama Sistemleri", src: "https://media.suciler.com/content/016.jpg", width: "158", height: "158" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 k6YS15", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Sulama Sistemleri", title: "Sulama Sistemleri", src: "https://media.suciler.com/content/017.jpg", width: "158", height: "158" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 k6YS15", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Sulama Sistemleri", title: "Sulama Sistemleri", src: "https://media.suciler.com/content/018.jpg", width: "158", height: "158" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 k6YS15", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Sulama Sistemleri", title: "Sulama Sistemleri", src: "https://media.suciler.com/content/019.jpg", width: "158", height: "158" } )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Submenu, {} )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default Media;