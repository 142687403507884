//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Featured from "./Content/Featured";
//Install
import Count from "./Content/Count";
//Install
import Shape from "./Content/Shape";
//Install
import Context from "./Content/Context";
//Install
import Produce from "./Content/Produce";
//Install
import Help from "./Content/Help";
//Install
import Form from "./Content/Form";
//Install
import SEO from "./Content/SEO";

//Element
class Main extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Erzurum'da Otomatik Sulama, Damlama Sulama Sistemleri - Suciler.com"
        //Element
        document.getElementsByTagName( "meta" )["description"].context = "Erzurum'da Sulama Sistemleri, Damlama Sulama Sistemleri, Otomatik Sulama Sistemleri, Bahçe Sulama Sistemleri, Yağmurlama Sulama Sistemleri Hizmetleri Sunulmaktadır."
        //Element
        this.Counter()
    }
    //Element
    Counter = () => {
        //Element
        const Count = document.querySelectorAll( ".Un2v98" )
        //Element
        const Speed = "400"
        //Element
        Count.forEach( Counters => {
            //Element
            const Animate = () => {
                //Element
                let Value = + Counters.getAttribute( "value" )
                //Element
                const Data = + Counters.innerHTML
                //Element
                const Time = Value / Speed
                //Element
                if( Data < Value ){
                    //Element
                    Counters.innerHTML = Math.ceil( Data + Time )
                    //Element
                    setTimeout( Animate, 1 )
                //Element
                } else {
                    //Element
                    Counters.innerHTML = Value
                }
            }
            //Element
            Animate()
        })
    }
    //Element
    render() {
        //Element
        const Product = {
            //Element
            spaceBetween  : "12",
            //Element
            slidesPerView : "6",
            //Element
            navigation    : {
                //Element
                nextEl    : "",
                //Element
                prevEl    : ""
            },
            //Element
            autoplay      : {
                //Element
                delay     : 8000
            },
            //Element
            breakpoints   : {
                //Element
                320       : {
                    //Element
                    slidesPerView : 1
                },
                //Element
                360       : {
                    //Element
                    slidesPerView : 1
                },
                //Element
                480       : {
                    //Element
                    slidesPerView : 2
                },
                //Element
                768       : {
                    //Element
                    slidesPerView : 2
                },
                //Element
                800       : {
                    //Element
                    slidesPerView : 3
                },
                //Element
                1024       : {
                    //Element
                    slidesPerView : 4
                },
                //Element
                1280       : {
                    //Element
                    slidesPerView : 6
                },
            },
            //Element
            modules       : [ Navigation, Pagination, Autoplay ]
        }
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 wM890a" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 v4O89C" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Featured, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 x46W3m" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:8 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Count, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 Wy021B" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"6" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Shape, {} )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:"6" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Context, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 m0N07k" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 yJ329g" },
                                //Element
                                React.createElement( Swiper, { className: "float-start w-100 position-relative overflow-hidden", ...Product },
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Produce, { ID: "0", Title: "Hunter Kumandalar", Photo: "006.jpg", Target: "/parcalar/kumandalar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Produce, { ID: "0", Title: "Hunter Aksesuarlar", Photo: "008.jpg", Target: "/parcalar/aksesuarlar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Produce, { ID: "0", Title: "Hunter Spreyler", Photo: "009.jpg", Target: "/parcalar/nozullar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Produce, { ID: "0", Title: "Hunter Nozullar", Photo: "010.jpg", Target: "/parcalar/nozullar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Produce, { ID: "0", Title: "Hunter Vanalar", Photo: "011.jpg", Target: "/parcalar/vanalar" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Produce, { ID: "0", Title: "Hunter Sensörler", Photo: "012.jpg", Target: "/parcalar/sensor" } )
                                    ),
                                    //Element
                                    React.createElement( SwiperSlide, {},
                                        //Element
                                        React.createElement( Produce, { ID: "0", Title: "Hunter Hydrawise", Photo: "007.jpg", Target: "/parcalar/hydrawise" } )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 wN0r67" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 Lr88Q6" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Help, { ID: "0", Title: "Kurulum Talebi", Summary: "Bugün konusunda uzman ve deneyimli kadroları ile siz değerli müşterilerimize kurulum talebi hizmetleri sunulur.", Photo: "002.jpg", Target: "/bilgilendirme" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Help, { ID: "0", Title: "Bakım Talebi", Summary: "Bahçenizin bakımını yaptırmak ve diğer sulama hizmetlerinden faydalanabilmek için hemen rezervasyon yaptırın.", Photo: "003.jpg", Target: "/bilgilendirme" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Help, { ID: "0", Title: "Tamir Talebi", Summary: "Sulama sistemleri için bir teknik servis teknisyeni çağırmak istiyorsanız buradan hemen tamir talebi oluşturabilirsin.", Photo: "004.jpg", Target: "/bilgilendirme" } )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 j94HK3" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:"6 offset-md-6" },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Form, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 bQ94U5" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( SEO, {} )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <main className="float-start w-100 position-relative">
                { Content }
            </main>
        );
    }
}
//Element
export default Main;