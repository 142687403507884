//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";
//Install
import propTypes from "prop-types";

//Element
class Cookie extends Component {
    //Element
    render() {
        //Element
        const { ID, Title } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Lq451p" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:11 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 vQ72z5" },
                        //Element
                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 text-truncate oN1k51" },
                            //Element
                            Title
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:1 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 vQ72z5" },
                        //Element
                        React.createElement( "button", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 bg-transparent border-0 nU170e", type: "button" },
                            //Element
                            "Tamam"
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
Cookie.propTypes = {
    ID    : propTypes.string.isRequired,
    Title : propTypes.string.isRequired
}
//Element
export default Cookie;