//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Icon } from "../../Media/Icon/001.svg";

//Element
class Brand extends Component {
    //Element
    Click = () => {
        //Element
        window.location.href = "/"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "button", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 bg-transparent border-0 G27q6Y", type: "button", onClick: this.Click },
            //Element
            React.createElement( "span", { className: "float-start w-auto p-0 m-0 A4e723" },
                //Element
                React.createElement( Icon, {} )
            ),
            //Element
            React.createElement( "span", { className: "float-start w-auto p-0 m-0 ms-3 v034YD" },
                //Element
                "Suciler.com"
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Brand;