//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Produce extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Photo, Target } = this.props
        //Element
        let Image = "https://media.suciler.com/produce/"
        //Element
        let URL   = Image + Photo
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-4 j5D7Y1", href: Target },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 Vk449i" } ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-4 m-0 iY070W" },
                //Element
                React.createElement( "picture", { className: "float-start w-100 position-relative Hhu256" },
                    //Element
                    React.createElement( "img", { alt: Title, title: Title, src: URL, width: "158", height: "158" } )
                )
            ),
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 qK0P64" },
                //Element
                React.createElement( "h3", { className: "float-start w-100 p-0 m-0 mt-3 text-center lh-1 Ut557S" },
                    //Element
                    Title
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
Produce.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Photo  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Produce;