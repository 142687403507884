//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { IoCloseOutline } from "react-icons/io5";

//Element
class Control extends Component {
    //Element
    Click = () => {
        //Element
        let Background = document.getElementById( "Product_Lightbox" )
        //Element
        let Body       = document.getElementsByTagName( "body" )[0]
        //Element
        Background.classList.add( "d-none" )
        //Element
        Body.classList.remove( "overflow-hidden" )
        //Element
        window.location.reload()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { id: "Product_Lightbox", className: "d-flex w-100 justify-content-center align-items-center position-fixed start-0 top-0 d-none J5bv36" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 A0v6a5" },
                            //Element
                            React.createElement( "div", { className: "float-start w-auto p-0 m-0 position-relative gF5x66" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Mu65h3" },
                                    //Element
                                    React.createElement( "h6", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 e6G973" } ),
                                    //Element
                                    React.createElement( "p", { className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 wB264Y" } ),
                                    //Element
                                    React.createElement( "ul", { id: "Control_X_Core", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Uygulama : Bireysel" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "İstasyon Sayısı : 2, 4, 6, 8" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Türü : Sabit" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Control_X2", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "İstasyon Sayısı : 4, 6, 8, 14" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Türü : Sabit" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Wi-Fi Capable with WAND" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Control_Pro_C", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "İstasyon Sayısı : 4 - 32" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Türü : Modüler" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Control_ICC_2", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "İstasyon Sayısı : 8 ila 54" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Türü : Modüler" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Control_HPC", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "İstasyon Sayısı : 4 - 32" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Türü : Modüler" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Wi-Fi" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Control_Pro_HC", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "İstasyon Sayısı : 6, 12, 24" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Türü : Sabit" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Wi-Fi" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Control_HCC", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "İstasyon Sayısı : 8 ila 54" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Türü : Modüler" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Wi-Fi" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Control_ICORE", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "İstasyon Sayısı : 6 - 42" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Türü : Modüler" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Control_Dual_ICORE", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Uygulama : Ticari" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "İstasyon Sayısı : 6 - 48" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Türü : I Core İçin Üretilmiş Dekoder" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Control_XC_Hybrid", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Uygulama : Bireysel / Ticari" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "İstasyon Sayısı : 6, 12" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Türü : Pille Çalışan, Sabit" ),
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "button", { className: "d-flex justify-content-center align-items-center position-absolute end-0 top-0 xT8051", type: "button", onClick: this.Click },
                                    //Element
                                    React.createElement( IoCloseOutline, {} )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Control;