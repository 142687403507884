//Install
import React from "react";
//Install
import "swiper/css";
//Install
import "bootstrap/dist/css/bootstrap.min.css";
//Install
import "./App.css";
//Install
import "./Responsive.css";
//Install
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//Install
import Header from "./Component/Header";
//Install
import Main from "./Component/Main";
//Install
import Product from "./Component/Product/Default";
//Install
import Rotors from "./Component/Product/Rotors";
//Install
import Remote from "./Component/Product/Remote";
//Install
import Sensor from "./Component/Product/Sensor";
//Install
import Accessories from "./Component/Product/Accessories";
//Install
import Nozzles from "./Component/Product/Nozzles";
//Install
import Hydrawise from "./Component/Product/Hydrawise";
//Install
import Valves from "./Component/Product/Valves";
//Install
import Micro from "./Component/Product/Micro";
//Install
import Macro from "./Component/Product/Macro";
//Install
import Decoder from "./Component/Product/Decoder";
//Install
import Control from "./Component/Product/Control";
//Install
import Corporation from "./Component/Tab/Corporation";
//Install
import Services from "./Component/Tab/Services";
//Install
import Career from "./Component/Tab/Career";
//Install
import Media from "./Component/Tab/Media";
//Install
import Reference from "./Component/Tab/Reference";
//Install
import Information from "./Component/Tab/Information";
//Install
import Contact from "./Component/Tab/Contact";
//Install
import Footer from "./Component/Footer";

//Element
function App() {
  //Element
  return (
    //Element
    <div className="App">
      <Header></Header>
      <BrowserRouter>
        <Routes> 
          <Route path="/" element={ <Main /> }></Route>
          <Route path="/parcalar" element={ <Product /> }></Route>
          <Route path="/parcalar/rotorlar" element={ <Rotors /> }></Route>
          <Route path="/parcalar/kumandalar" element={ <Remote /> }></Route>
          <Route path="/parcalar/sensor" element={ <Sensor /> }></Route>
          <Route path="/parcalar/aksesuarlar" element={ <Accessories /> }></Route>
          <Route path="/parcalar/nozullar" element={ <Nozzles /> }></Route>
          <Route path="/parcalar/hydrawise" element={ <Hydrawise /> }></Route>
          <Route path="/parcalar/vanalar" element={ <Valves /> }></Route>
          <Route path="/parcalar/mikro-sulama" element={ <Micro /> }></Route>
          <Route path="/parcalar/ek-parcalar" element={ <Macro /> }></Route>
          <Route path="/parcalar/decoder" element={ <Decoder /> }></Route>
          <Route path="/parcalar/kontrol-uniteleri" element={ <Control /> }></Route>
          <Route path="/kurumsal" element={ <Corporation /> }></Route>
          <Route path="/sunulan-hizmetler" element={ <Services /> }></Route>
          <Route path="/kariyer-firsatlari" element={ <Career /> }></Route>
          <Route path="/medya" element={ <Media /> }></Route>
          <Route path="/referanslar" element={ <Reference /> }></Route>
          <Route path="/bilgilendirme" element={ <Information /> }></Route>
          <Route path="/hemen-ulasin" element={ <Contact /> }></Route>
          <Route path="*" element={ <Navigate to="/" /> } />
        </Routes>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}
//Element
export default App;
