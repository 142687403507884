//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { IoCloseOutline } from "react-icons/io5";

//Element
class Rotors extends Component {
    //Element
    Click = () => {
        //Element
        let Background = document.getElementById( "Product_Lightbox" )
        //Element
        let Body       = document.getElementsByTagName( "body" )[0]
        //Element
        Background.classList.add( "d-none" )
        //Element
        Body.classList.remove( "overflow-hidden" )
        //Element
        window.location.reload()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { id: "Product_Lightbox", className: "d-flex w-100 justify-content-center align-items-center position-fixed start-0 top-0 d-none J5bv36" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 A0v6a5" },
                            //Element
                            React.createElement( "div", { className: "float-start w-auto p-0 m-0 position-relative gF5x66" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Mu65h3" },
                                    //Element
                                    React.createElement( "h6", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 e6G973" } ),
                                    //Element
                                    React.createElement( "p", { className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 wB264Y" } ),
                                    //Element
                                    React.createElement( "ul", { id: "Rotors_PGJ", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Uygulama : Bireysel" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Atış Mesafesi : 4.3 - 11.6 m" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Debi : 0,13 - 1,23 m³/sa" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Giriş Çapı : ½" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Rotors_SRM", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Uygulama : Bireysel" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Atış Mesafesi : 4,0 - 10,7 m" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Debi : 0,08 - 0,82 m³/sa" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Giriş Çapı : ½" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Rotors_PGP_ADJ", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Uygulama : Bireysel" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Atış Mesafesi : 6,4 - 15,8 m" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Debi : 0,10 - 3,22 m³/sa" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Giriş Çapı : ¾ BSP" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Rotors_PGP_Ultra", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Uygulama : Bireysel/Ticari" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Atış Mesafesi : 4,9 - 14,0 m" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Debi : 0,07 - 3,23 m³/sa" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Giriş Çapı : ¾ BSP" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Rotors_PGP_Ultra_PRB", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Atış Mesafesi : 4,9 - 14,0 m" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Debi : 0,07 - 2,22 m³/sa" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Giriş Çapı : ¾ BSP" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Rotors_I20", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Uygulama : Bireysel/Ticari" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Atış Mesafesi : 4,9 - 14,0 m" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Debi : 0,07 - 3,23 m³/sa" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Giriş Çapı : ¾ BSP" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Rotors_I25", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Uygulama : Ticari/Belediye" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Atış Mesafesi : 11,9 - 21,6 m" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Debi : 0,82 - 7,24 m³/sa" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Giriş Çapı : 1 BSP" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Rotors_I40", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Uygulama : Ticari/Belediye" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Atış Mesafesi : 13,4 - 23,2 m" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Debi : 1,63 - 6,84 m³/sa" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Giriş Çapı : 1 BSP" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Rotors_I80", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Atış Mesafesi : 19,2 - 29,6 m" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Debi : 4,59 - 13,5 m³/sa" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Giriş Çapı : 40 mm BSP" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Rotors_I90", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Uygulama : Büyük Yarıçaplı Ticari/ Belediye" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Atış Mesafesi : 22,3 - 31,4 m" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Debi : 6,7 - 19,04 m³/sa" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Giriş Çapı : 1½ BSP" ),
                                        )
                                    ),
                                ),
                                //Element
                                React.createElement( "button", { className: "d-flex justify-content-center align-items-center position-absolute end-0 top-0 xT8051", type: "button", onClick: this.Click },
                                    //Element
                                    React.createElement( IoCloseOutline, {} )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Rotors;