//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Count extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 position-relative A49o37" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:4 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 ps-5 pe-5 m-0 dS2q53" },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 Un2v98", value: "1830" },
                            //Element
                            "1.830"
                        ),
                        //Element
                        React.createElement( "span", { className: "float-end w-auto p-0 m-0 F62fz9" },
                            //Element
                            "Kurumsal"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:4 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 ps-5 pe-5 m-0 dS2q53" },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 Un2v98", value: "1130" },
                            //Element
                            "1.130"
                        ),
                        //Element
                        React.createElement( "span", { className: "float-end w-auto p-0 m-0 F62fz9" },
                            //Element
                            "Bireysel"
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:4 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 ps-5 pe-5 m-0 dS2q53" },
                        //Element
                        React.createElement( "span", { className: "float-start w-auto p-0 m-0 Un2v98", value: "3083" },
                            //Element
                            "1.038"
                        ),
                        //Element
                        React.createElement( "span", { className: "float-end w-auto p-0 m-0 F62fz9" },
                            //Element
                            "Beğeni"
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Count;