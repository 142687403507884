//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Submenu from "../Shortcode/Tab/Submenu";
//Install
import Product from "../Shortcode/Product/Control";

//Element
class Control extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Kontrol Üniteleri : Erzurum Sulama Sistemleri - Suciler.com"
        //Element
        this.Mark()
        //Element
        this.Footer()
    }
    //Element
    Mark = () => {
        //Element
        let Selector = document.querySelector( ".Cs75y0 li:nth-child(3) a" )
        //Element
        Selector.classList.add( "Active" )
    }
    //Element
    Footer = () => {
        //Element
        let Content = document.getElementsByClassName( "Q2tC03" )[0]
        //Element
        Content.classList.remove( "mt-4" )
    }
    //Element
    Click = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Background = document.getElementById( "Product_Lightbox" )
        //Element
        let Title      = document.getElementsByClassName( "e6G973" )[0]
        //Element
        let Summary    = document.getElementsByClassName( "wB264Y" )[0]
        //Element
        let Body       = document.getElementsByTagName( "body" )[0]
        //Element
        Background.classList.remove( "d-none" )
        //Element
        Body.classList.add( "overflow-hidden" )
        //Element
        Title.innerHTML = e.nativeEvent.target.attributes[0].textContent
        //Element
        if( e.nativeEvent.target.attributes[0].textContent === "X-CORE" ){
            //Element
            Summary.innerHTML = "Bu basit sulama kontrol ünitesi, isteğe bağlı yerinde akıllı ET sulama ayarları ve uzaktan kumanda ile çalışma imkanı sunar."
            //Element
            let Panel = document.getElementById( "Control_X_Core" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "X2" ){
            //Element
            Summary.innerHTML = "Bu çevrimiçi özellikli denetleyici hızlı program programlama ve gelişmiş su tasarrufu özellikleri sunar."
            //Element
            let Panel = document.getElementById( "Control_X2" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Pro C" ){
            //Element
            Summary.innerHTML = "Kolay programlama ve esnek istasyon genişleme özelliği Pro-C'yi bireysel ve hafif ticari sistemler için profesyonellerin tercihi yapar."
            //Element
            let Panel = document.getElementById( "Control_Pro_C" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "ICC 2" ){
            //Element
            Summary.innerHTML = "Bu güvenilir kontrol ünitesi, Centralus™ bulut tabanlı kontrol sistemine yükseltme seçeneğiyle geleneksel, çift-kablolu veya hibrit olarak istasyonları çalıştırabilir."
            //Element
            let Panel = document.getElementById( "Control_ICC_2" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "HPC" ){
            //Element
            Summary.innerHTML = "Bu akıllı ve esnek kontrol çözümü, popüler Pro-C™ Kontrol Ünitesinin modülerliğini Hydrawise® Yazılımının gücüyle birleştirir."
            //Element
            let Panel = document.getElementById( "Control_HPC" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Pro HC" ){
            //Element
            Summary.innerHTML = "Bireysel ve hafif ticari uygulamalar için bu sağlam ve profesyonel kaliteli Wi-Fi kontrol ünitesini kullanın."
            //Element
            let Panel = document.getElementById( "Control_Pro_HC" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "HCC" ){
            //Element
            Summary.innerHTML = "Hydrawise®'ın gücünü uygun fiyatlı olarak bireysel, ticari ve kamu sektörü projelerine taşıyın."
            //Element
            let Panel = document.getElementById( "Control_HCC" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "I-Core" ){
            //Element
            Summary.innerHTML = "Debi izleme ve çift-kablo yetenekler, I-Core'u bağımsız orta ölçekli ticari ve üst düzey konut projeleri için ideal bir yer haline getirir."
            //Element
            let Panel = document.getElementById( "Control_ICORE" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Dual For I-Core" ){
            //Element
            Summary.innerHTML = "Geleneksel I-Core™ sistemlerini çift kablo kontrole yükseltmek için bu opsiyonel eklenti modülünü ekleyerek malzeme ve işçilikten tasarruf etme."
            //Element
            let Panel = document.getElementById( "Control_Dual_ICORE" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "XC Hybrid" ){
            //Element
            Summary.innerHTML = "Bu ekonomik pil veya güneş enerjili kontrol ünitesiyle elektriğin mümkün olmadığı peyzaj alanlarını etkili bir şekilde yönetin."
            //Element
            let Panel = document.getElementById( "Control_XC_Hybrid" )
            //Element
            Panel.classList.remove( "d-none" )
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Tv84y2" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 pM13z9" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 S7j79P" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:"9 offset-md-3" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative Sm2277" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-5 m-0 mt-3 uP71E5" },
                                        //Element
                                        "Kontrol Üniteleri"
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-5 pt-4 pb-4 m-0 mb-5 Ur628J" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "X-CORE", title: "X-CORE", src: "https://media.suciler.com/product/001.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "X-CORE"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "X2", title: "X2", src: "https://media.suciler.com/product/002.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "X2"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Pro C", title: "Pro C", src: "https://media.suciler.com/product/003.png", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "Pro C"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "ICC 2", title: "ICC 2", src: "https://media.suciler.com/product/004.png", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "ICC 2"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "HPC", title: "HPC", src: "https://media.suciler.com/product/005.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "HPC"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Pro HC", title: "Pro HC", src: "https://media.suciler.com/product/006.png", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "Pro HC"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "HCC", title: "HCC", src: "https://media.suciler.com/product/007.png", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "HCC"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "I-Core", title: "I-Core", src: "https://media.suciler.com/product/008.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "I-Core"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Dual For I-Core", title: "Dual For I-Core", src: "https://media.suciler.com/product/009.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "Dual For I-Core"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "XC Hybrid", title: "XC Hybrid", src: "https://media.suciler.com/product/010.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate gB956B" },
                                                            //Element
                                                            "XC Hybrid"
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Submenu, {} ),
            //Element
            React.createElement( Product, {} )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default Control;