//Install
import React, { Component } from "react";

//Element
class Featured extends Component {
    //Element
    Click = () => {
        //Element
        window.location.href = "/bilgilendirme"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 ps-5 m-0 uN459e" },
            //Element
            React.createElement( "h1", { className: "float-start w-auto p-0 m-0 B371q0" },
                //Element
                "Erzurum'da ",
                //Element
                React.createElement( "br", {} ),
                //Element
                "Damlama ",
                //Element
                React.createElement( "br", {} ),
                //Element
                "Sulama Sistemleri "
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 S8a89c" } ),
            //Element
            React.createElement( "div", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 mt-4 nW395v" },
                //Element
                React.createElement( "button", { className: "float-start p-0 m-0 text-center W4g512", type: "button", onClick: this.Click },
                    //Element
                    "Ön Bilgilendirme Talebi"
                ),
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-4 d6J923", href: "tel:+905462207512" },
                    //Element
                    "0546 220 75 12"
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Featured;