//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { ReactComponent as Next } from "../../Media/Icon/008.svg";

//Element
class Submenu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-fixed Tl356z" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "float-start w-100 position-relative" },
                            //Element
                            React.createElement( "ul", { className: "float-start w-100 p-0 m-0 Tj682G" },
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", href: "/" },
                                        //Element
                                        "Başlangıç",
                                        //Element
                                        React.createElement( Next, {} )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", href: "/kurumsal" },
                                        //Element
                                        "Kurumsal",
                                        //Element
                                        React.createElement( Next, {} )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", href: "/sunulan-hizmetler" },
                                        //Element
                                        "Sunulan Hizmetler",
                                        //Element
                                        React.createElement( Next, {} )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", href: "/parcalar" },
                                        //Element
                                        "Parçalar",
                                        //Element
                                        React.createElement( Next, {} )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", href: "/referanslar" },
                                        //Element
                                        "Referanslar",
                                        //Element
                                        React.createElement( Next, {} )
                                    )
                                ),
                                //Element
                                React.createElement( "li", { className: "float-start w-100" },
                                    //Element
                                    React.createElement( "a", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0", href: "/hemen-ulasin" },
                                        //Element
                                        "Hemen Ulaşın",
                                        //Element
                                        React.createElement( Next, {} )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Submenu;