//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Copyright extends Component {
    //Element
    render() {
        //Element
        const { ID, Title } = this.props
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 ps-5 m-0 Vs816m" },
            //Element
            React.createElement( "p", { className: "float-start w-100 p-0 m-0 b8yT11" },
                //Element
                Title
            )
        )   
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
Copyright.propTypes = {
    ID    : propTypes.string.isRequired,
    Title : propTypes.string.isRequired
}
//Element
export default Copyright;