//Install
import React, { Component } from "react";

//Element
class Tag extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 pe-5 ps-5 m-0 mt-5 mb-5 xM547x" },
            //Element
            React.createElement( "ul", { className: "float-start w-100 p-0 m-0 oE11e4" },
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 pe-3 ps-3", href: "/parcalar/kontrol-uniteleri" },
                        //Element
                        "Kontrol Üniteleri"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 pe-3 ps-3", href: "/parcalar/decoder" },
                        //Element
                        "Basınç Kontrol"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 pe-3 ps-3", href: "/" },
                        //Element
                        "Damlama Sulama"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 pe-3 ps-3", href: "/parcalar/mikro-sulama" },
                        //Element
                        "Mikro Sulama"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 pe-3 ps-3", href: "/parcalar/rotorlar" },
                        //Element
                        "Bahçe Sulama"
                    )
                ),
                //Element
                React.createElement( "li", { className: "float-start w-auto" },
                    //Element
                    React.createElement( "a", { className: "float-start w-auto p-0 pe-3 ps-3", href: "/" },
                        //Element
                        "Saha Sulama"
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Tag;