//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Sitemap from "../Shortcode/Tab/Sitemap";
//Install
import Submenu from "../Shortcode/Tab/Submenu";

//Element
class Contact extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Hemen Ulaşın : Erzurum Sulama Sistemleri - Suciler.com"
        //Element
        this.Mark()
        //Element
        this.Footer()
    }
    //Element
    Mark = () => {
        //Element
        let Selector = document.querySelector( ".Cs75y0 li:last-child a" )
        //Element
        Selector.classList.add( "Active" )
    }
    //Element
    Footer = () => {
        //Element
        let Content = document.getElementsByClassName( "Q2tC03" )[0]
        //Element
        Content.classList.remove( "mt-4" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Tv84y2" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 pM13z9" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 S7j79P" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:"9 offset-md-3" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative Sm2277" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-5 m-0 mt-3 uP71E5" },
                                        //Element
                                        "Fiyat teklifi almak ve sunulan hizmetler hakkında daha fazla bilgi edinmek için ekibimizle hemen iletişime geçin."
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-5 pt-4 pb-4 m-0 mb-5 Ur628J" },
                                        //Element
                                        React.createElement( "p", {},
                                            //Element
                                            "Bilgi, yaşam sürüp giderken alınan kararlarda son derece büyük önem taşır. Bu neden ile özel uzmanlardan oluşan ekibimiz size yardımcı olmaya her an hazırdır."
                                        ),
                                        //Element
                                        React.createElement( "p", {},
                                            //Element
                                            "Sulama sistemleri kurulumu, çeşitli çözümlerimize kadar bilgi ihtiyacı duyduğunuz tüm konular için bize ulaşın, size yardımcı olmaktan daima mutluluk duyarız."
                                        ),
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-1 xP179v" } ),
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 N03l2x" },
                                            //Element
                                            React.createElement( Row, {},
                                                //Element
                                                React.createElement( Col, { md:6 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 nJ50N1" },
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 Pi081r" },
                                                            //Element
                                                            "Kurumsal Bilgilendirme"
                                                        ),
                                                        //Element
                                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 iT20I3" },
                                                            //Element
                                                            "Çağrı merkezi numaraları 08.00 ile 18.00 saatleri arasında tüm talepleriniz için",
                                                            //Element
                                                            React.createElement( "br", {} ),
                                                            //Element
                                                            "hizmetinizdedir."
                                                        ),
                                                        //Element
                                                        React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-2 ND5r57", href: "tel:+905462207512" },
                                                            //Element
                                                            "0546 220 75 12"
                                                        )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:6 },
                                                    //Element
                                                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-1 nJ50N1" },
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 Pi081r" },
                                                            //Element
                                                            "Hemen Bilgi Edinin"
                                                        ),
                                                        //Element
                                                        React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 iT20I3" },
                                                            //Element
                                                            "Senin icin daha neler yapabilirim? Benimle hemen iletişim kurabilirsin, senden haber",
                                                            //Element
                                                            React.createElement( "br", {} ),
                                                            //Element
                                                            "bekliyorum."
                                                        ),
                                                        //Element
                                                        React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-2 ND5r57", href: "mailto:info@suciler.com" },
                                                            //Element
                                                            "info@suciler.com"
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Submenu, {} )
        )
        //Element
        return (
            <main className="float-start w-100 position-relative">
                { Content }
            </main>
        );
    }
}
//Element
export default Contact;