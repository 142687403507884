//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Sitemap from "../Shortcode/Tab/Sitemap";
//Install
import Submenu from "../Shortcode/Tab/Submenu";

//Element
class Services extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Sunulan Hizmetler : Erzurum Sulama Sistemleri - Suciler.com"
        //Element
        this.Mark()
        //Element
        this.Footer()
    }
    //Element
    Mark = () => {
        //Element
        let Selector = document.querySelector( ".Cs75y0 li:nth-child(2) a" )
        //Element
        Selector.classList.add( "Active" )
    }
    //Element
    Footer = () => {
        //Element
        let Content = document.getElementsByClassName( "Q2tC03" )[0]
        //Element
        Content.classList.remove( "mt-4" )
    }
    //Element
    Click = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Background = document.getElementsByClassName( "J5bv36" )[0]
        //Element
        let Box        = document.getElementsByClassName( "pB7g34" )[0]
        //Element
        let Body       = document.getElementsByTagName( "body" )[0]
        //Element
        let URL = e.target.currentSrc
        //Element
        let Image      = document.getElementById( "Lightbox" )
        //Element
        Image.src      = URL
        //Element
        Background.classList.remove( "d-none" )
        //Element
        Box.classList.remove( "d-none" )
        //Element
        Body.classList.add( "overflow-hidden" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Tv84y2" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 pM13z9" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 S7j79P" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:"9 offset-md-3" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative Sm2277" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-5 m-0 mt-3 uP71E5" },
                                        //Element
                                        "Bahçe Sulama Sistemleri İle 80% Su Tasarrufu Sağlayın."
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-5 pt-4 pb-4 m-0 mb-5 Ur628J" },
                                        //Element
                                        React.createElement( "p", {},
                                            //Element
                                            "Suciler sulama sistemleri yüksek kaliteli ve etkili çözümler sağlayan bölgesel bir işletme olarak hizmet sunmaktadır. Çeşitli ürünlerimiz bireysel bahçelerden stadyumlara, hobi bahçelerine, lunaparklara, şehir parklarına, ticari komplekslere, otellere ve belediye hizmet binalarına kadar bölgenin her yerinde görülebilir. "
                                        ),
                                        //Element
                                        React.createElement( "p", {},
                                            //Element
                                            "Sulama sistemleri konusundaki profesyonellerin talepleri düşünülerek tasarlanan Hunter sulama ürünleri grubu Popup dişli tahrikli rotorlar, yüksek verimli döner nozullar, sprey sprinkler, vanalar, kontrol üniteleri, merkezi kontrol üniteleri, profesyonel damla sulama ve hava sensörleri içermektedir."
                                        ),
                                        //Element
                                        React.createElement( "p", {},
                                            //Element
                                            "Başlangıçtan bu yana Suciler'in temel odağı az su ve enerji kullanarak fonksiyonel ve özel ambiyansa sahip peyzaj alanı sunan çözümler sunacak ürünler geliştirmek olmuştur."
                                        ),
                                        //Element
                                        React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-1 Vh322X" },
                                            //Element
                                            React.createElement( Row, {},
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 r6IP51", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Suciler", title: "Suciler", src: "http://media.suciler.com/content/001.jpg", width: "154", height: "154" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 r6IP51", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Suciler", title: "Suciler", src: "http://media.suciler.com/content/003.jpg", width: "154", height: "154" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 r6IP51", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Suciler", title: "Suciler", src: "http://media.suciler.com/content/004.jpg", width: "154", height: "154" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 r6IP51", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Suciler", title: "Suciler", src: "http://media.suciler.com/content/005.jpg", width: "154", height: "154" } )
                                                    )
                                                ),
                                                //Element
                                                React.createElement( Col, { md:1 },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 r6IP51", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "img", { alt: "Suciler", title: "Suciler", src: "http://media.suciler.com/content/007.jpg", width: "154", height: "154" } )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Submenu, {} )
        )
        //Element
        return (
            <main className="float-start w-100 position-relative">
                { Content }
            </main>
        );
    }
}
//Element
export default Services;