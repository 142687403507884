//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Submenu from "../Shortcode/Tab/Submenu";
//Install
import Product from "../Shortcode/Product/Sensor";

//Element
class Sensor extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Sensörler : Erzurum Sulama Sistemleri - Suciler.com"
        //Element
        this.Mark()
        //Element
        this.Footer()
    }
    //Element
    Mark = () => {
        //Element
        let Selector = document.querySelector( ".Cs75y0 li:nth-child(3) a" )
        //Element
        Selector.classList.add( "Active" )
    }
    //Element
    Footer = () => {
        //Element
        let Content = document.getElementsByClassName( "Q2tC03" )[0]
        //Element
        Content.classList.remove( "mt-4" )
    }
    //Element
    Click = (e) => {
        //Element
        e.preventDefault()
        //Element
        let Background = document.getElementById( "Product_Lightbox" )
        //Element
        let Title      = document.getElementsByClassName( "e6G973" )[0]
        //Element
        let Summary    = document.getElementsByClassName( "wB264Y" )[0]
        //Element
        let Body       = document.getElementsByTagName( "body" )[0]
        //Element
        Background.classList.remove( "d-none" )
        //Element
        Body.classList.add( "overflow-hidden" )
        //Element
        Title.innerHTML = e.nativeEvent.target.attributes[0].textContent
        //Element
        if( e.nativeEvent.target.attributes[0].textContent === "Solar Sync" ){
            //Element
            Summary.innerHTML = "Bu sensör, su kullanımını azaltmak ve bitki sağlığını iyileştirmek için yerel iklim koşullarına göre kontrol ünitesi çalışma zamanlarını otomatik olarak ayarlar."
            //Element
            let Panel = document.getElementById( "Sensor_Solar_Sync" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Rain Clik" ){
            //Element
            Summary.innerHTML = "Su israfını önlemek için dahili Quick Response™ (Hızlı Tepki) teknolojisi yağmur yağmaya başlar başlamaz sulamayı anında kapatır."
            //Element
            let Panel = document.getElementById( "Solar_Rain_Clik" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Mini Clik" ){
            //Element
            Summary.innerHTML = "Bu sensör önceden belirlenmiş bir yağmur seviyesi tespit ettiğinde su israfını önlemek için programlı sulamayı durdurur."
            //Element
            let Panel = document.getElementById( "Solar_Mini_Clik" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Soil Clik" ){
            //Element
            Summary.innerHTML = "Bu sensör, toprağın nemini ölçerek ve önceden ayarlanmış bir seviyeye ulaşıldığında sulamayı keserek su israfını önler."
            //Element
            let Panel = document.getElementById( "Solar_Soil_Clik" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Flow Sync" ){
            //Element
            Summary.innerHTML = "Her Bütçeye Uygun Hassas Debi İzleme"
            //Element
            let Panel = document.getElementById( "Solar_Flow_Sync" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Flow Clik" ){
            //Element
            Summary.innerHTML = "Delinme veya Sızıntı Olduğunda Flow-Clik, Su Akışını Anında Durdurmayı Sağlar"
            //Element
            let Panel = document.getElementById( "Solar_Flow_Clik" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Wind Clik" ){
            //Element
            Summary.innerHTML = "Bu sensör, rüzgar hızı arttığında su kapsama alanını verimli ve yaya yollarını ve karayollarını güvende tutar."
            //Element
            let Panel = document.getElementById( "Solar_Wind_Clik" )
            //Element
            Panel.classList.remove( "d-none" )
        //Element
        } else if( e.nativeEvent.target.attributes[0].textContent === "Freeze Clik" ){
            //Element
            Summary.innerHTML = "Sprinklerin bir don olayı sırasında çalışmasını durdurmak ve peyzaj alanını, yürüyüş yollarını ve karayollarını buzlu ortamlardan korumak için bu sensörü kullanın."
            //Element
            let Panel = document.getElementById( "Solar_Freeze_Clik" )
            //Element
            Panel.classList.remove( "d-none" )
        }
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Tv84y2" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 pM13z9" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 S7j79P" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:"9 offset-md-3" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative Sm2277" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-5 m-0 mt-3 uP71E5" },
                                        //Element
                                        "Sensörler"
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-5 pt-4 pb-4 m-0 mb-5 Ur628J" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Solar Sync", title: "Solar Sync", src: "https://media.suciler.com/product/061.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "Solar Sync"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Rain Clik", title: "Rain Clik", src: "https://media.suciler.com/product/062.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "Rain Clik"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Mini Clik", title: "Mini Clik", src: "https://media.suciler.com/product/063.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "Mini Clik"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Soil Clik", title: "Soil Clik", src: "https://media.suciler.com/product/064.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "Soil Clik"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Flow Sync", title: "Flow Sync", src: "https://media.suciler.com/product/065.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "Flow Sync"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Flow Clik", title: "Flow Clik", src: "https://media.suciler.com/product/068.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "Flow Clik"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Wind Clik", title: "Wind Clik", src: "https://media.suciler.com/product/066.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "Wind Clik"
                                                        )
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 Up18V9" },
                                                    //Element
                                                    React.createElement( "a", { className: "float-start w-100 p-0 m-0 Zg04t3", href: "/", onClick: this.Click },
                                                        //Element
                                                        React.createElement( "picture", { className: "float-start w-100 p-0 m-0 Dz7328" },
                                                            //Element
                                                            React.createElement( "img", { alt: "Freeze Clik", title: "Freeze Clik", src: "https://media.suciler.com/product/067.jpg", width: "100", height: "100" } )
                                                        ),
                                                        //Element
                                                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center gB956B" },
                                                            //Element
                                                            "Freeze Clik"
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Submenu, {} ),
            //Element
            React.createElement( Product, {} )
        )
        //Element
        return (
            <main className="float-start w-100">
                { Content }
            </main>
        );
    }
}
//Element
export default Sensor;