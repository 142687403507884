//Install
import React, { Component } from "react";
//Install
import { BsArrowUp } from "react-icons/bs";

//Element
class Navigation extends Component {
    //Element
    Click = () => {
        //Element
        document.body.scrollTop = 0
        //Element
        document.documentElement.scrollTop = 0
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Ez67s6" },
            //Element
            React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 position-absolute start-0 top-0 YZm645", type: "button", onClick: this.Click },
                //Element
                React.createElement( BsArrowUp, {} )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Navigation;