//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { IoCloseOutline } from "react-icons/io5";

//Element
class Micro extends Component {
    //Element
    Click = () => {
        //Element
        let Background = document.getElementById( "Product_Lightbox" )
        //Element
        let Body       = document.getElementsByTagName( "body" )[0]
        //Element
        Background.classList.add( "d-none" )
        //Element
        Body.classList.remove( "overflow-hidden" )
        //Element
        window.location.reload()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { id: "Product_Lightbox", className: "d-flex w-100 justify-content-center align-items-center position-fixed start-0 top-0 d-none J5bv36" },
            //Element
            React.createElement( Container, {},
                //Element
                React.createElement( Row, {},
                    //Element
                    React.createElement( Col, { md:12 },
                        //Element
                        React.createElement( "div", { className: "d-flex w-auto justify-content-center align-items-center p-0 m-0 A0v6a5" },
                            //Element
                            React.createElement( "div", { className: "float-start w-auto p-0 m-0 position-relative gF5x66" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 Mu65h3" },
                                    //Element
                                    React.createElement( "h6", { className: "float-start w-100 p-0 ps-4 pe-4 m-0 e6G973" } ),
                                    //Element
                                    React.createElement( "p", { className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 wB264Y" } ),
                                    //Element
                                    React.createElement( "ul", { id: "Micro_Ecomat", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Uygulama : Toprak Altı Damla Sulama" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Uygulama : Bireysel/Ticari" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Debi : 2,2 l/sa" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Micro_PLD16", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Debi : 1,4 / 2,2 / 3,8 l/sa" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Micro_HDL", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Debi : 0,4, 0,6, 0,9 GPH" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Micro_MLD", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Bağlantı Parçaları : Tüm ¼ uç bağlantı parçaları" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Yüzey Sulama, Kısa Mesafe ve Saksılar" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Debi : 1,5-3,2 l/sa" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Micro_Pldml", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Bağlantı Parçaları : 16-18 mm Damla Sulama Borusu" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Kullanım Alanları : Uçlu ve Premium Bağlantı Parçaları" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Micro_Ecowrap", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-100" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Yüzey Altı Sulama Çim, Bahçeler, Çalılar, Ağaçlar" ),
                                        )
                                    ),
                                    //Element
                                    React.createElement( "ul", { id: "Micro_BTT", className: "float-start w-100 p-0 pe-4 ps-4 pb-3 m-0 mt-3 d-none Dl412q" },
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "İstasyon Sayısı : 1, 2" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0" }, "Türü : Pille Çalışan, Sabit" ),
                                        ),
                                        //Element
                                        React.createElement( "li", { className: "float-start w-50" },
                                            //Element
                                            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-1" }, "Bluetooth Uygulama Kontrolü" ),
                                        )
                                    )
                                ),
                                //Element
                                React.createElement( "button", { className: "d-flex justify-content-center align-items-center position-absolute end-0 top-0 xT8051", type: "button", onClick: this.Click },
                                    //Element
                                    React.createElement( IoCloseOutline, {} )
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100">
                { Content }
            </div>
        );
    }
}
//Element
export default Micro;