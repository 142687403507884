//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Sitemap from "../Shortcode/Tab/Sitemap";
//Install
import Submenu from "../Shortcode/Tab/Submenu";

//Element
class Reference extends Component {
    //Element
    componentDidMount(){
        //Element
        document.title = "Kimlere Hizmet Sunuldu? : Erzurum Sulama Sistemleri - Suciler.com"
        //Element
        this.Mark()
        //Element
        this.Footer()
    }
    //Element
    Mark = () => {
        //Element
        let Selector = document.querySelector( ".Cs75y0 li:nth-child(5) a" )
        //Element
        Selector.classList.add( "Active" )
    }
    //Element
    Footer = () => {
        //Element
        let Content = document.getElementsByClassName( "Q2tC03" )[0]
        //Element
        Content.classList.remove( "mt-4" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Tv84y2" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 pM13z9" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 position-relative" },
                //Element
                React.createElement( "div", { className: "float-start w-100 position-absolute start-0 top-0 S7j79P" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:"9 offset-md-3" },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative Sm2277" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-100 p-5 m-0 mt-3 uP71E5" },
                                        //Element
                                        "Hizmet kalitemizi seçen ve bizimle çalışmayı tercih eden referanslarımızı burada bulabilirsin."
                                    ),
                                    //Element
                                    React.createElement( "div", { className: "float-start w-100 p-5 pt-4 pb-4 m-0 mb-5 Ur628J" },
                                        //Element
                                        React.createElement( Row, {},
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 Wy378q" },
                                                    //Element
                                                    React.createElement( "picture", { className: "float-start w-100 p-0 m-0 k6YS15" },
                                                        //Element
                                                        React.createElement( "img", { alt: "Erzurum Valiliği", title: "Erzurum Valiliği", src: "https://media.suciler.com/reference/001.jpg", width: "154", height: "154" } )
                                                    ),
                                                    //Element
                                                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate K19dR4" },
                                                        //Element
                                                        "Erzurum Valiliği"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 Wy378q" },
                                                    //Element
                                                    React.createElement( "picture", { className: "float-start w-100 p-0 m-0 k6YS15" },
                                                        //Element
                                                        React.createElement( "img", { alt: "Erzurum B.B.", title: "Erzurum B.B.", src: "https://media.suciler.com/reference/002.jpg", width: "154", height: "154" } )
                                                    ),
                                                    //Element
                                                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate K19dR4" },
                                                        //Element
                                                        "Erzurum B.B."
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 Wy378q" },
                                                    //Element
                                                    React.createElement( "picture", { className: "float-start w-100 p-0 m-0 k6YS15" },
                                                        //Element
                                                        React.createElement( "img", { alt: "Atatürk Üniversitesi", title: "Atatürk Üniversitesi", src: "https://media.suciler.com/reference/003.jpg", width: "154", height: "154" } )
                                                    ),
                                                    //Element
                                                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate K19dR4" },
                                                        //Element
                                                        "Atatürk Üniversitesi"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 Wy378q" },
                                                    //Element
                                                    React.createElement( "picture", { className: "float-start w-100 p-0 m-0 k6YS15" },
                                                        //Element
                                                        React.createElement( "img", { alt: "Erzurum Teknik Üniversitesi", title: "Erzurum Teknik Üniversitesi", src: "https://media.suciler.com/reference/004.jpg", width: "154", height: "154" } )
                                                    ),
                                                    //Element
                                                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate K19dR4" },
                                                        //Element
                                                        "Erzurum Teknik Üniversitesi"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-2 Wy378q" },
                                                    //Element
                                                    React.createElement( "picture", { className: "float-start w-100 p-0 m-0 k6YS15" },
                                                        //Element
                                                        React.createElement( "img", { alt: "Erzurum Emniyet Müdürlüğü", title: "Erzurum Emniyet Müdürlüğü", src: "https://media.suciler.com/reference/005.jpg", width: "154", height: "154" } )
                                                    ),
                                                    //Element
                                                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate K19dR4" },
                                                        //Element
                                                        "Erzurum Emniyet Müdürlüğü"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 Wy378q" },
                                                    //Element
                                                    React.createElement( "picture", { className: "float-start w-100 p-0 m-0 k6YS15" },
                                                        //Element
                                                        React.createElement( "img", { alt: "İl Millî Eğitim Müdürlüğü", title: "İl Millî Eğitim Müdürlüğü", src: "https://media.suciler.com/reference/006.jpg", width: "154", height: "154" } )
                                                    ),
                                                    //Element
                                                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate K19dR4" },
                                                        //Element
                                                        "İl Millî Eğitim Müdürlüğü"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 Wy378q" },
                                                    //Element
                                                    React.createElement( "picture", { className: "float-start w-100 p-0 m-0 k6YS15" },
                                                        //Element
                                                        React.createElement( "img", { alt: "Aile ve Sosyal Hizmetler İl Md.", title: "Aile ve Sosyal Hizmetler İl Md.", src: "https://media.suciler.com/reference/007.jpg", width: "154", height: "154" } )
                                                    ),
                                                    //Element
                                                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate K19dR4" },
                                                        //Element
                                                        "Aile ve Sosyal Hizmetler İl Md."
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 Wy378q" },
                                                    //Element
                                                    React.createElement( "picture", { className: "float-start w-100 p-0 m-0 k6YS15" },
                                                        //Element
                                                        React.createElement( "img", { alt: "Gençlik ve Spor İl Müdürlüğü", title: "Gençlik ve Spor İl Müdürlüğü", src: "https://media.suciler.com/reference/008.jpg", width: "154", height: "154" } )
                                                    ),
                                                    //Element
                                                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate K19dR4" },
                                                        //Element
                                                        "Gençlik ve Spor İl Müdürlüğü"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 Wy378q" },
                                                    //Element
                                                    React.createElement( "picture", { className: "float-start w-100 p-0 m-0 k6YS15" },
                                                        //Element
                                                        React.createElement( "img", { alt: "Yakutiye Belediyesi", title: "Yakutiye Belediyesi", src: "https://media.suciler.com/reference/011.jpg", width: "154", height: "154" } )
                                                    ),
                                                    //Element
                                                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate K19dR4" },
                                                        //Element
                                                        "Yakutiye Belediyesi"
                                                    )
                                                )
                                            ),
                                            //Element
                                            React.createElement( Col, { md:1 },
                                                //Element
                                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 Wy378q" },
                                                    //Element
                                                    React.createElement( "picture", { className: "float-start w-100 p-0 m-0 k6YS15" },
                                                        //Element
                                                        React.createElement( "img", { alt: "Bahçeşehir Koleji", title: "Bahçeşehir Koleji", src: "https://media.suciler.com/reference/010.jpg", width: "154", height: "154" } )
                                                    ),
                                                    //Element
                                                    React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-2 text-center text-truncate K19dR4" },
                                                        //Element
                                                        "Bahçeşehir Koleji"
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Submenu, {} )
        )
        //Element
        return (
            <main className="float-start w-100 position-relative">
                { Content }
            </main>
        );
    }
}
//Element
export default Reference;