//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import Menu from "./Footer/Menu";
//Install
import Tag from "./Footer/Tag";
//Install
import Contact from "./Footer/Contact";
//Install
import Navigation from "./Footer/Navigation";
//Install
import Copyright from "./Footer/Copyright";
//Install
import Sitemap from "./Footer/Sitemap";
//Install
import Lightbox from "./Shortcode/Lightbox";
//Install
import Default from "./Mobile/Footer";

//Element
class Footer extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Zp8333" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 Q2tC03" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 M6q15K" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:9 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative" },
                                    //Element
                                    React.createElement( Menu, {} ),
                                    //Element
                                    React.createElement( Tag, {} )
                                )
                            ),
                            //Element
                            React.createElement( Col, { md:3 },
                                //Element
                                React.createElement( "div", { className: "float-start w-100 position-relative" },
                                    //Element
                                    React.createElement( Contact, { ID: "0", Number: "0546 220 75 12", Mail: "info@suciler.com" } )
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( "div", { className: "d-flex justify-content-between align-items-center position-absolute A6u093" },
                    //Element
                    React.createElement( Navigation, {} )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 k875GB" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:8 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Copyright, { ID: "0", Title: "© Telif Hakkı Suciler.com Tüm Hakları Saklıdır" } )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:4 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 position-relative" },
                                //Element
                                React.createElement( Sitemap, {} )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( Lightbox, {} ),
            //Element
            React.createElement( Default, {} )
        )
        //Element
        return (
            <footer className="float-start w-100 position-relative">
                { Content }
            </footer>
        );
    }
}
//Element
export default Footer;