//Install
import React, { Component } from "react";
//Install
import { LuPhoneCall } from "react-icons/lu";

//Element
class Search extends Component {
    //Element
    Click = () => {
        //Element
        window.location.href = "/hemen-ulasin"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 Be06b8" },
            //Element
            React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 bg-transparent border-0 Px180R", type: "button", onClick: this.Click },
                //Element
                React.createElement( LuPhoneCall, {} )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Search;