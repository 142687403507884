//Install
import React, { Component } from "react";
//Install
import { Row, Col } from "react-bootstrap";

//Element
class Menu extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 pe-5 ps-5 m-0 Xm789J" },
            //Element
            React.createElement( Row, {},
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 Cp86l9" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 lh-1 Ed843m" },
                            //Element
                            "Kurumsal"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 qI2851" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/kurumsal" },
                                    //Element
                                    "Kurumsal"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1", href: "/medya" },
                                    //Element
                                    "Medya"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1", href: "/referanslar" },
                                    //Element
                                    "Referanslar"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1", href: "/kariyer-firsatlari" },
                                    //Element
                                    "Kariyer"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1", href: "/hemen-ulasin" },
                                    //Element
                                    "Hemen Ulaşın"
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 Cp86l9" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 lh-1 Ed843m" },
                            //Element
                            "Önemli Parçalar"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 qI2851" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3", href: "/parcalar/kumandalar" },
                                    //Element
                                    "Kumandalar"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1", href: "/parcalar/rotorlar" },
                                    //Element
                                    "Rotorlar"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1", href: "/parcalar/sensor" },
                                    //Element
                                    "Sensörler"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1", href: "/parcalar/aksesuarlar" },
                                    //Element
                                    "Aksesuarlar"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1", href: "/parcalar/hydrawise" },
                                    //Element
                                    "Hydrawise"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1", href: "/parcalar/nozullar" },
                                    //Element
                                    "Nozullar"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1", href: "/parcalar/vanalar" },
                                    //Element
                                    "Vanalar"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1", href: "/parcalar/ek-parcalar" },
                                    //Element
                                    "Ek Parçalar"
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 Cp86l9" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 lh-1 Ed843m" },
                            //Element
                            "Markalar"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 qI2851" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/" },
                                    //Element
                                    "Hunter"
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 Cp86l9" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 lh-1 Ed843m" },
                            //Element
                            "Bakım ve Tamir"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 qI2851" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/bilgilendirme" },
                                    //Element
                                    "Kurulum Talebi"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1", href: "/bilgilendirme" },
                                    //Element
                                    "Bakım Talebi"
                                )
                            ),
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-1", href: "/bilgilendirme" },
                                    //Element
                                    "Tamir Talebi"
                                )
                            )
                        )
                    )
                ),
                //Element
                React.createElement( Col, { md:3 },
                    //Element
                    React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-5 Cp86l9" },
                        //Element
                        React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 lh-1 Ed843m" },
                            //Element
                            "Hemen Ulaşın"
                        ),
                        //Element
                        React.createElement( "ul", { className: "float-start w-100 p-0 m-0 mt-3 qI2851" },
                            //Element
                            React.createElement( "li", { className: "float-start w-100" },
                                //Element
                                React.createElement( "a", { className: "float-start w-100 p-0 m-0", href: "/hemen-ulasin" },
                                    //Element
                                    "İletişim Bilgileri"
                                )
                            )
                        )
                    )
                )
            )
        )
        //Element
        return (
            <div className="float-start w-100 position-relative">
                { Content }
            </div>
        );
    }
}
//Element
export default Menu;